<template>
  <v-card class="form">
    <v-card-title class="options-title"> Opciones de Cuenta </v-card-title>
    <v-card-text class="options-description">
      <v-container>
        <v-row align="center">
          <v-col cols="12" sm="8">
            <h3>ELIMINAR USUARIO</h3>
            <p>
              Esto eliminará la cuenta permanentemente. No guardaremos ningún
              dato después de está acción y no se podrá deshacer.
            </p>
          </v-col>
          <v-col cols="12" sm="4">
            <v-dialog v-model="dialog" max-width="350">
              <template v-slot:activator="{ on }">
                <v-btn color="red" v-on="on" class="options-dangerous-button">
                  ELIMINAR USUARIO
                </v-btn>
              </template>
              <v-card class="form">
                <v-card-title class="options-title"
                  >ELIMINAR USUARIO
                </v-card-title>
                <v-card-text class="options-description">
                  ¿Estás seguro? Esta acción no se puede deshacer.
                </v-card-text>
                <v-card-actions class="options-actions">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="options-dangerous-button"
                    color="red"
                    @click="deleteUser"
                    :loading="loading"
                  >
                    Eliminar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { deleteUser } from "@/services/user";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
    };
  },
  methods: {
    async deleteUser() {
      try {
        this.loading = true;
        await deleteUser();
        await this.$store.dispatch("clear");
        localStorage.clear();
        this.$router.go(0);
      } catch (e) {
        this.$snackbar.error(
          `No se pudo eliminar el usuario. Si el problema persiste contácte con nosotros: ${e.data}`,
          { text: "AYUDA", action: this.$support.show }
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass">
@mixin button()
  color: white
  background-color: var(--v-red-base) !important
  font-weight: 700
  font-family: "Josefin Sans", sans-serif
  padding-right: 20px
  padding-left: 20px
  border-radius: 10px

  &:hover
    color: white
    background-color: var(--v-brown-base)!important

.options-title
  font-family: 'Gurajada', sans-serif
  font-size: 2em
  color: var(--v-blue-base)
  line-height: 1

.options-description
  font-size: calc(10px + 1vh)
  color: var(--v-black-base) !important
  font-family: Gupter, sans-serif

  h3
    font-family: "Josefin Sans", sans-serif
    margin-bottom: 1em

  .options-dangerous-button
    @include button()

.options-actions .options-dangerous-button .v-btn__content
  @include button
</style>
