<template>
  <v-container fluid>
    <v-row justify="space-around">
      <v-col cols="12" md="10" lg="8">
        <payment-settings />
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="10" lg="8">
        <dangerous-settings />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DangerousSettings from "@/components/profile/DangerousSettings";
import PaymentSettings from "@/components/profile/PaymentSettings";

export default {
  components: { DangerousSettings, PaymentSettings },
};
</script>
