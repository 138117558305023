<template>
  <v-card class="form">
    <v-card-title> Subscripciones Activas </v-card-title>
    <v-card-text class="form-body">
      <v-container fill-height>
        <v-row v-if="loading" justify="center" align="center">
          <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          />
        </v-row>
        <v-row
          v-else-if="subscriptions.length === 0"
          justify="center"
          align="center"
        >
          No te has subscrito a ningún curso
        </v-row>
        <v-row
          v-else
          v-for="subscription in subscriptions"
          :key="subscription.id"
        >
          <v-col cols="12">
            <subscription-card :subscription="subscription" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { listUserSubscriptions } from "@/services/payments";
import SubscriptionCard from "@/components/profile/SubscriptionCard";
import { authGetters } from "@/store/auth";

export default {
  components: { SubscriptionCard },
  data() {
    return {
      subscriptions: [],
      loading: true,
    };
  },
  created() {
    return this.getSubscriptions();
  },
  watch: {
    userIsLogged(newValue, oldValue) {
      if (!oldValue && newValue) this.getSubscriptions();
    },
  },
  computed: {
    userIsLogged() {
      return this.$store.getters[authGetters.userIsLogged];
    },
  },
  methods: {
    async getSubscriptions() {
      try {
        const subscriptions = await listUserSubscriptions();
        this.subscriptions = subscriptions || [];
      } catch (e) {
        this.$snackbar.error(
          "No se pudo cargar las subscripciones activas, inténtelo mas tarde. Si el problema persiste, contacta con nosotros",
          { text: "AYUDA", action: this.$support.show }
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass">
.v-card__title
  font-family: 'Gurajada', sans-serif
  font-size: 2em
  color: var(--v-blue-base)
  line-height: 1
</style>
