import { http } from "./axiosUtils";

/**
 *
 * Returns all user active subscriptions
 *
 */
export const listUserSubscriptions = () => http.get("checkout/subscriptions");

/**
 *
 * Returns subscription portal session url
 * @returns {redirectUrl} containing url to the platform for this customer
 */
export const createCustomerPortalUrl = (customerId) =>
  http.post("checkout/subscriptions/portal/session", { customerId });
