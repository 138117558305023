<template>
  <v-card>
    <v-container fluid fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="8">
          <v-card-title v-text="subscription.title" />
          <v-card-subtitle>
            {{ subscription.price | priceFormat }}
            <div
              v-if="subscription.status === 'past_due'"
              style="padding-top: 20px"
            >
              <strong>AVISO</strong>: no se ha podido realizar el cobro de este
              mes, se intentará de nuevo (por favor revisa tu información de
              pago).
            </div>
            <span v-else-if="subscription.cancellationTs">
              (<strong>CANCELADO</strong>. Último día de acceso
              {{ subscription.cancellationTs | dateFormat }})
            </span>
            <span v-else-if="subscription.nextPaymentTs">
              (Próximo pago el {{ subscription.nextPaymentTs | dateFormat }})
            </span>
          </v-card-subtitle>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn @click="goToPortal" class="form-button">Gestionar</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { createCustomerPortalUrl } from "@/services/payments";

export default {
  name: "SubscriptionCard",
  props: {
    subscription: Object,
  },
  filters: {
    priceFormat(price) {
      const priceString = `${price}`;
      const priceLength = priceString.length;
      const cents = priceString.substring(priceLength - 2, priceLength);
      const euros = priceString.substring(0, priceLength - 2);
      return `${euros},${cents} Euros al mes`;
    },
    dateFormat(ts) {
      const options = { month: "long", day: "numeric" };
      return new Date(ts).toLocaleDateString("es-ES", options);
    },
  },
  methods: {
    async goToPortal() {
      const windowReference = window.open();
      try {
        this.$loading.loading();
        const customerId = this.subscription.customerId;
        const { redirectUrl } = await createCustomerPortalUrl(customerId);
        windowReference.location = redirectUrl;
      } catch (e) {
        windowReference.close();
        this.$snackbar.error(
          "No se pudo acceder al portal, inténtalo mas tarde o escribe a hola@unperroenlaciudad.es y te ayudaremos en lo que necesites."
        );
      } finally {
        this.$loading.stopLoading();
      }
    },
  },
};
</script>

<style scoped lang="sass">

.v-card
  background-color: var(--v-cream-base)

  .v-card__title
    font-size: 23px
    font-weight: bold
    color: var(--v-black-base)
    font-family: "Josefin Sans", sans-serif
    margin-bottom: 1em
    text-transform: uppercase

  .v-card__subtitle
    font-size: calc(10px + 1vh)
    color: var(--v-black-base)
    font-family: Gupter, sans-serif


  .form-button
    color: white
    background-color: var(--v-red-base) !important
    font-weight: 700
    font-family: "Josefin Sans", sans-serif
    padding-right: 20px
    padding-left: 20px
    border-radius: 10px

    &:hover
      color: white
      background-color: var(--v-brown-base)!important
</style>
